import './App.css';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header>
        <nav>
          <ul className='nav-list'>
            <li><a href="/">Home</a></li>
            <li><a href="/">About</a></li>
            <li><a href="/">Contact</a></li>
          </ul>
        </nav>
      </header>
      <div className='content'>
        <div>
           content
        </div>
        <div>
           content 2
        </div>
        <div>
           content 3
        </div>
        <div>
           content 4
        </div>
        <div>
           content 5
        </div>
        <div>
           content 6
        </div>
        <div>
           content 7
        </div>
        <div>
           content 8
        </div>
        <div>
           content 9
        </div> 
      </div>
    </div>
  );
}

export default App;
